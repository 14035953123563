import React from 'react';
import '../style/main.css';
import './Hero.css';
import Navigation from './Navigation';
import overlay from "../assets/images/Archipelagos_Labs-BkgdShape.svg";
import video from "../assets/video/Main_edit_3_DARK_SML.mp4";
import video_img from "../assets/images/Archipelagos-Labs_mobile-video-still.jpg";
import { Fade } from "react-awesome-reveal";

function Hero() {
    return (
        <div className="HeroSection px-0 lg:px-12 xl:px-40">
            <div className='HeroContent'>
                <video className='VideoBkgd hidden md:block' autoPlay loop muted style={{
                    position: "absolute",
                    width: "100%",
                    maxWidth: "1920px",
                    height: "100%",
                    maxHeight: "720px",
                    left: "50%",
                    top: "0%",
                    objectFit: "cover",
                    transform: "translate(-50%, -0%)",
                    zIndex: "-10"
                }}>
                    <source src={video} type="video/mp4" />
                </video>
                <image className='VideoStill md:hidden' style={{
                    position: "absolute",
                    width: "inherit",
                    height: "100%",
                    maxHeight: "720px",
                    left: "50%",
                    top: "0%",
                    objectFit: "cover",
                    transform: "translate(-50%, -0%)",
                    zIndex: "-10"
                }}>
                    <img src={video_img} className="Mobile_Still" alt="Mobile Video Still Image" style={{
                        height: "100%",
                        maxHeight: "720px",
                        maxWidth: "initial"
                    }} />
                </image>

                <div className="HeroContainer flex flex-col">
                    <Navigation />
                    <div className="SectionContent flex flex-col px-8 lg:px-12 xl:px-16">
                        <div className="Content flex flex-col">
                            <div className="HeroTitle lg:flex">
                                <h1 className="lg:flex lg:inline-flex font-medium mb-4 text-4_5xl">
                                    <Fade delay={400} duration={2400} triggerOnce='true'>
                                        <span className='mb-4'>Every Litre.&nbsp;</span>
                                    </Fade>
                                    <span className="hidden"><br></br></span>
                                    <Fade delay={1200} duration={2400} triggerOnce='true'>
                                        <span className='mb-4'>Every Watt.&nbsp;</span>
                                    </Fade>
                                    <span className="hidden"><br></br></span>
                                    <Fade delay={2000} duration={2400} triggerOnce='true'>
                                        <span className="HeroHighLight mb-4">Liberated.</span>
                                    </Fade>
                                </h1>
                            </div>
                            <div className="HeroStrapline">
                                <Fade delay={3200} duration={2400} triggerOnce='true'>
                                    <p className="font-normal text-xl sm:text-3xl">Conserving natural resources by <span className="hidden sm:inline lg:hidden"><br></br></span>unlocking the <span className="hidden lg:inline"><br></br></span>potential of data to <span className="hidden sm:inline lg:hidden"><br></br></span>drive strategic interventions.</p>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    );
}

export default Hero;