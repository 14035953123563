import React from 'react';
import './Footer.css';
import email from '../assets/images/Archipelagos_Labs-EmailIcon.svg';
import linkedin from '../assets/images/Archipelagos_Labs-LinkedInIcon.svg';

function Footer() {
    return (
        <div className="FooterSection px-0 lg:px-12 xl:px-40">
            <div className="MainContainer my-0 mx-auto">
                <div className="SectionContent px-8 lg:px-12 xl:px-16 py-6 lg:py-8">
                    <div className="Content">
                        <div className="FooterColumn">
                            <div className="FooterLink FooterEmail">
                                <div className="LinkIcon">
                                    <img src={email} className="EmailIcon" alt="icon" />
                                </div>
                                <div className="LinkTitle">
                                    <a
                                        className="text-white"
                                        href="mailto:info@archipelagos-labs.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        info@archipelagos-labs.com
                                    </a>
                                </div>
                            </div>
                            {/* <div className="hidden FooterLink FooterLinkedin">
                                <div className="LinkIcon">
                                    <img src={linkedin} className="LinkedinIcon" alt="icon" />
                                </div>
                                <div className="LinkTitle">
                                    <h3>Linkedin</h3>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

        </div >

    );
}

export default Footer;