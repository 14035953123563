import React from 'react';
import './App.css';
import Hero from './components/Hero';
import Intro from './components/Intro';
import Transformation from './components/Transformation';
import Impact from './components/Impact';
import Quote from './components/Quote';
import Architecture from './components/Architecture';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Hero />
      <Quote />
      <Intro />
      <Transformation />
      <Impact />
      <Architecture />
      <Footer />
    </div>
  );
}

export default App;
