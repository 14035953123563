import React from 'react';
import './Impact.css';
import { Fade } from "react-awesome-reveal";
import icon1 from '../assets/images/Archipelagos_Labs-TransparentIcon.svg'
import icon2 from '../assets/images/Archipelagos_Labs-ActionIcon.svg'
import icon3 from '../assets/images/Archipelagos_Labs-CostIcon.svg'
import icon4 from '../assets/images/Archipelagos_Labs-EnvironmentalIcon.svg'

function Impact() {
    return (
        <div className="ImpactSection px-0 lg:px-12 xl:px-40">
            <div className="MainContainer my-0 mx-auto">
                <div className="SectionContent px-8 lg:px-12 xl:px-16 py-16 lg:py-24">
                    <Fade delay={400} duration={1800} triggerOnce='true'>
                        <div className="Title pb-16">
                            <h2>Measurable Operational & Environmental Impact</h2>
                        </div>
                    </Fade>
                    <Fade delay={800} duration={2400} triggerOnce='true'>
                        <div className="Content flex flex-col">
                            <div className="ContentColumn w-full flex flex-col xl:flex-row columns-1 xl:gap-16 xl:columns-2">
                                <div className="ImpactSubSection pb-12">
                                    <div className="ImpactSubHeader pb-2.5">
                                        <div className="ImpactSubTitle">
                                            <h3 className="font-medium text-xl">Transparent Observability</h3>
                                        </div>
                                        <div className="ImpactSubIcon">
                                            <img src={icon1} className="ImpactIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ImpactSubContent">
                                        <p className="mt-3">
                                            Releasing insights from siloed, archived and incompatible islands of data into an integrated platform allows the big picture to be viewed through a single pane of glass.
                                        </p>
                                    </div>
                                </div>
                                <div className="ImpactSubSection pb-12">
                                    <div className="ImpactSubHeader pb-2.5">
                                        <div className="ImpactSubTitle">
                                            <h3 className="font-medium text-xl">Environmental Impact</h3>
                                        </div>
                                        <div className="ImpactSubIcon">
                                            <img src={icon4} className="ImpactIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ImpactSubContent">
                                        <p className="mt-3">
                                            Our innovation-driven approach prioritises environmental sustainability, reduces your ecological footprint, and provides unequivocal information that you can deliver to your customers and shareholders.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ContentColumn w-full flex flex-col xl:flex-row columns-1 xl:gap-16 xl:columns-2">
                                <div className="ImpactSubSection pb-12 xl:pb-0">
                                    <div className="ImpactSubHeader pb-2.5">
                                        <div className="ImpactSubTitle">
                                            <h3 className="font-medium text-xl">Transferring Data into Action</h3>
                                        </div>
                                        <div className="ImpactSubIcon">
                                            <img src={icon2} className="ImpactIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ImpactSubContent">
                                        <p className="mt-3">
                                            The process of transforming information can reveal significant insights, allowing for effective interventions. Data that spans decades can help detect and visualise changes over time and forecast future movements.
                                        </p>
                                    </div>
                                </div>
                                <div className="ImpactSubSection">
                                    <div className="ImpactSubHeader pb-2.5">
                                        <div className="ImpactSubTitle">
                                            <h3 className="font-medium text-xl">Cost Optimisation</h3>
                                        </div>
                                        <div className="ImpactSubIcon">
                                            <img src={icon3} className="ImpactIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ImpactSubContent">
                                        <p className="mt-3">
                                            Legacy operations can be enhanced and decades of practical knowledge shared across your ecosystem. Success hinges on field operations, therefore it is crucial to equip your team with the correct tools. But whilst technology facilitates this, it's your people and processes that will ultimately determine success.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>

        </div >

    );
}

export default Impact;