import React from 'react';
import './Quote.css';
import quotes from '../assets/images/Archipelagos_Labs-QuotesIcon.svg';
import { Fade } from "react-awesome-reveal";

function Quote() {
    return (
        <div className="QuoteSection px-0 lg:px-12 xl:px-40">
            <div className="MainContainer my-0 mx-auto">
                <div className="SectionContent px-8 lg:px-12 xl:px-16 py-16 lg:py-24">
                    <Fade delay={400} duration={1800} triggerOnce='true'>
                        {/* <div className="QuoteIcon w-11/12 lg:w-3/4">
                            <img src={quotes} className="QuotesImg w-16 pb-4 md:pb-0" alt="icon" />
                        </div> */}
                        <div className="Quote mt-4 w-11/12 lg:w-3/4">
                            <p className="font-semibold text-xl lg:text-2xl">
                                "Wide-scale environmental monitoring and the management of natural resources are acute challenges requiring innovative ideas in conjunction with secure and robust systems. Archipelagos Labs' extensive domain expertise and use of secure yet open data and technology standards ensures that we continue to rise to these challenges."
                            </p>
                        </div>
                        {/* <div className="QuoteIcon flex flew-row justify-end w-11/12 lg:w-3/4">
                            <img src={quotes} className="QuotesImg w-16 pt-4 md:pt-0" alt="icon" />
                        </div> */}
                    </Fade>
                    <Fade delay={800} duration={2400} triggerOnce='true'>
                        <div className="Quotee mt-6 w-11/12 lg:w-3/4 font-light text-lg">
                            <p className='uppercase'>Simon Chapple - Head of Data Technology, IoT Research & Innovation Service, IT Infrastructure Directorate in ISG, University of Edinburgh</p>
                        </div>
                    </Fade>
                </div>
            </div>

        </div >

    );
}

export default Quote;