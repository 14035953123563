import React from 'react';
import './Architecture.css';
import { Fade } from "react-awesome-reveal";
import icon1 from '../assets/images/Archipelagos_Labs-SecurityIcon.svg'
import icon2 from '../assets/images/Archipelagos_Labs-ScalabilityIcon.svg'
import icon3 from '../assets/images/Archipelagos_Labs-AvailabilityIcon.svg'
import icon4 from '../assets/images/Archipelagos_Labs-PerformanceIcon.svg'
import icon5 from '../assets/images/Archipelagos_Labs-DevicesIcon.svg'
import icon6 from '../assets/images/Archipelagos_Labs-AccessibilityIcon.svg'

function Architecture() {
    return (
        <div className="ArchitectureSection px-0 lg:px-12 xl:px-40">
            <div className="MainContainer my-0 mx-auto">
                <div className="SectionContent px-8 lg:px-12 xl:px-16 py-16 lg:py-24">
                    <Fade delay={400} duration={1800} triggerOnce='true'>
                        <div className="Title pb-16">
                            <h2>Secure, Scalable & Open Architecture</h2>
                        </div>
                    </Fade>
                    <Fade delay={800} duration={2400} triggerOnce='true'>
                        <div className="Content flex flex-col">
                            <div className="ContentColumn w-full flex flex-col xl:flex-row columns-1 xl:gap-16 xl:columns-2">
                                <div className="ArchitectureSubSection pb-12">
                                    <div className="ArchitectureSubHeader pb-2.5">
                                        <div className="ArchitectureSubTitle">
                                            <h3 className="font-medium text-xl">Information Security</h3>
                                        </div>
                                        <div className="ArchitectureSubIcon">
                                            <img src={icon1} className="ArchitectureIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ArchitectureSubContent">
                                        <p className="mt-3">
                                            The platform is lightweight, GDPR compliant and follows industry best practices and standards, ensuring data confidentiality and availability.
                                        </p>
                                    </div>
                                </div>
                                <div className="ArchitectureSubSection pb-12">
                                    <div className="ArchitectureSubHeader pb-2.5">
                                        <div className="ArchitectureSubTitle">
                                            <h3 className="font-medium text-xl">Performance</h3>
                                        </div>
                                        <div className="ArchitectureSubIcon">
                                            <img src={icon4} className="ArchitectureIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ArchitectureSubContent">
                                        <p className="mt-3">
                                            Infrastructure built for providing low latency, low packet loss, and high overall network quality, allowing for quick resource deployment to meet application needs.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ContentColumn w-full flex flex-col xl:flex-row columns-1 xl:gap-16 xl:columns-2">
                                <div className="ArchitectureSubSection pb-12">
                                    <div className="ArchitectureSubHeader pb-2.5">
                                        <div className="ArchitectureSubTitle">
                                            <h3 className="font-medium text-xl">Platform Scalability</h3>
                                        </div>
                                        <div className="ArchitectureSubIcon">
                                            <img src={icon2} className="ArchitectureIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ArchitectureSubContent">
                                        <p className="mt-3">
                                            Scale resources up or down depending on business needs, promoting cost-effectiveness and responsiveness to user demands.
                                        </p>
                                    </div>
                                </div>
                                <div className="ArchitectureSubSection pb-12">
                                    <div className="ArchitectureSubHeader pb-2.5">
                                        <div className="ArchitectureSubTitle">
                                            <h3 className="font-medium text-xl">Existing Devices & Data</h3>
                                        </div>
                                        <div className="ArchitectureSubIcon">
                                            <img src={icon5} className="ArchitectureIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ArchitectureSubContent">
                                        <p className="mt-3">
                                            Leverage your existing devices and assets, merging space and ground without the need or expense of replacing them.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ContentColumn w-full flex flex-col xl:flex-row columns-1 xl:gap-16 xl:columns-2">
                                <div className="ArchitectureSubSection pb-12 xl:pb-0">
                                    <div className="ArchitectureSubHeader pb-2.5">
                                        <div className="ArchitectureSubTitle">
                                            <h3 className="font-medium text-xl">High Availabilty</h3>
                                        </div>
                                        <div className="ArchitectureSubIcon">
                                            <img src={icon3} className="ArchitectureIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ArchitectureSubContent">
                                        <p className="mt-3">
                                            Support for large numbers of devices with the ability to isolate and partition network connectivity and middleware to achieve high fault tolerance and availability.
                                        </p>
                                    </div>
                                </div>
                                <div className="ArchitectureSubSection">
                                    <div className="ArchitectureSubHeader pb-2.5">
                                        <div className="ArchitectureSubTitle">
                                            <h3 className="font-medium text-xl">Data Accessibility</h3>
                                        </div>
                                        <div className="ArchitectureSubIcon">
                                            <img src={icon6} className="ArchitectureIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ArchitectureSubContent">
                                        <p className="mt-3">
                                            Open data formats ensure that your data can be readily accessed on multiple devices and platforms when you need it.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>

            </div>

        </div >

    );
}

export default Architecture;