import React from 'react';
import './Intro.css';
import { Fade } from "react-awesome-reveal";

function Intro() {
    return (
        <div className="IntroSection px-0 lg:px-12 xl:px-40">
            <div className="MainContainer my-0 mx-auto">
                <div className="SectionContent px-8 lg:px-12 xl:px-16 py-16 lg:py-24">
                    <Fade delay={400} duration={1800} triggerOnce='true'>
                        <div className="Title pb-16">
                            <h2>An Uncertain Future</h2>
                        </div>
                    </Fade>
                    <Fade delay={800} duration={2400} triggerOnce='true'>
                        <div className="Content columns-1 gap-16 xl:columns-2">
                            <p>
                                The management of natural resources stands at the intersection of rapid technological  advancements, ever-evolving customer expectations, and challenging regulatory shifts. Increased cyber threats pose significant risks to infrastructure, while customers demand increasingly personalised and efficient services.
                                <br></br>
                                <br></br>
                                Historically, the presence of siloed data, trapped in disparate systems and formats, has posed a significant barrier to making data-driven decisions due to the absence of a comprehensive, unified view of the information.
                                <br></br>
                                <br></br>
                                Current solutions often lack a holistic approach, favouring closed, proprietary systems with technological and economic lock-ins. Making an incorrect investment decision is worse than making no decision at all. The pressure on management teams to make investment commitments that will impact their organisations for decades can be incredibly daunting.
                            </p>
                        </div>
                    </Fade>
                </div>
            </div>

        </div >

    );
}

export default Intro;